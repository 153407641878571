<template>
    <div>
        <!-- Form Modal -->
        <b-modal
            id="modal-season-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            @ok="submit"
            size="sm"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="port.id === null">Register Port</h5>
                <h5 v-if="port.id !== null">Edit Port</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button
                    size="sm"
                    variant="primary"
                    @click="ok()"
                    :disabled="saving"
                >
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button
                    size="sm"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="12" md="8">
                                <b-form-group label-for="coutry_id" label="From Country">
                                    <validation-provider #default="{ errors }" name="From Country" rules="">
                                        <b-form-select
                                            id="coutry_id"
                                            name="coutry_id"
                                            v-model="port.country_id"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :options="countries"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.country_id">{{ serverErrors.country_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group label-for="code" label="Code">
                                    <validation-provider #default="{ errors }" name="Code" rules="">
                                        <b-form-select
                                            id="code"
                                            name="code"
                                            v-model="port.country_id"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :options="codes"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-group label-for="name" label="Port Name">
                                    <validation-provider #default="{ errors }" name="Port Name" rules="">
                                        <b-form-input
                                            id="name"
                                            name="name"
                                            v-model="port.name"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            value=""
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- <b-card>
                            {{ port }}
                        </b-card> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            
                            <b-button
                                variant="primary"
                                size="sm"
                                @click="invokeCreateForm()"
                            >
                                <span class="text-nowrap">Register Port</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(name)="data">
                    <span v-if="data.item.country.code">
                        {{ data.item.name }}, {{ data.item.country.code.substring(0, 1).toUpperCase() + data.item.country.code.substring(1).toLowerCase() }}
                    </span>
                    <span v-else>
                        {{ data.item.name }}
                    </span>
                </template>
                <template #cell(country)="data">
                    {{ data.item.country.name }}
                </template>
                <template #cell(created_at)="data">
                    {{ data.item.created_at | moment('DD/MM/YYYY HH:mm:ss') }}
                </template>

                <template #cell(updated_at)="data">
                    {{ data.item.updated_at | moment('DD/MM/YYYY HH:mm:ss') }}
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormTextarea,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { required } from '@validations'
    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import portsStoreModule from '@/views/cromis/shipping/ports/portsStoreModule'
    import usePortsList from '@/views/cromis/shipping/ports/usePortsList'
    import moment from 'moment'

    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormTextarea,
            BSpinner,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context) {
            const serverErrors = ref(null)
            const saving = ref(false)
            const dataForm = ref(null)
            const myModal = ref(null)

            const countries = ref([{ value: null, text: 'Select...' }])
            const codes = ref([{ value: null, text: 'Select...' }])

            const port = ref({
                id: null,
                name: null,
                country_id: null,
            })
            
            const CROMIS_STORE_MODULE_NAME = 'cromis-port'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, portsStoreModule)

            // UnRegister on leave
            onUnmounted(async () => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-port/countries')
                    .then(response => {
                        response.data.countries.map((country) => {
                            countries.value.push({ value: country.id, text: country.name })
                            codes.value.push({ value: country.id, text: country.code })
                        })
                    })
                    .catch(error => {
                       console.log(error)
                    })
            })

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
            } = usePortsList()

            const isFormValid = async () => {
                let isValid = false 
                await dataForm.value.validate().then(success => {
                    isValid = success
                })

                return isValid
            }

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()

                serverErrors.value = null
                // Handle form submit
                if (port.value.id === null || port.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(port.value)
            }

            const invokeUpdateForm = (item) => {
                serverErrors.value = null
                saving.value = false

                port.value = {
                    id: item.id,
                    name: item.name,
                    country_id: item.country.id,
                }

                myModal.value.show()
            }

            const invokeCreateForm = () => {
                serverErrors.value = null
                saving.value = false

                port.value = {
                    id: null,
                    name: null,
                    country_id: null,
                }

                myModal.value.show()
            }

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                
                await store.dispatch('cromis-port/create', port.value)
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Port registered successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-port/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to port ${response.data.name} has been saved successfully!`,
                            showConfirmButton: true,
                            timer: 2000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-port/remove', id)
                            .then(response => {
                                refetch()
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: true,
                                })
                            })
            }

            return {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                dataForm,
                myModal,
                countries,
                codes,
                port,
                refetch,
                invokeCreateForm,
                invokeUpdateForm,
                isFormValid,
                submit,
                serverErrors,
                saving,
                handleCreate,
                handleUpdate,
                remove,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>